<template>
	<div class="group-list">
		<no-data v-if="goods_list.length<=0"></no-data>
		<van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
			<van-list finished-text="加载完毕" v-model="isloading" :finished="finished" @load="loadData">
				
				<div  v-for="(item,index) in goods_list" :key="index">
					<van-cell-group>
						<!-- 循环部分 -->
						<van-cell  @click="toDetail(item.id)">
							<div class="flex">
								<van-image style="width: 2.5rem;height: 2.5rem;" :src="item.images && item.images.length > 0 ? item.images[0] : ''" fit="cover">
									<template v-slot:loading>
										<van-loading type="spinner" size="20" />
									</template>
								</van-image>
								<div class="ml5" style="width: 4.4rem;">
								  <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 14px;">{{item.moldbaby_name}}</div>
								  <div style="color: gray;width: 4.4rem;font-size: 12px;height: 1.4rem;overflow: hidden;line-height: 0.5rem;" >{{item.moldbady_desc}}</div>
								  <div class="" style="color: gray;font-size: 14px;">零售价：￥{{item.retail_price}}</div>
								</div>
								
								<div class="h-right ml5">
									<div class="h-price">￥{{item.real_price}}</div>
									<van-progress class="h-progress" :percentage="item.rate" />
									<div class="h-sale">已售 {{item.rate | number()}}%</div>
									<div class="h-btn">立即购买</div>
								</div>
								
							</div>
						</van-cell>
						<!--  -->
					</van-cell-group>
				</div>
			</van-list>
		</van-pull-refresh>
		
		<!-- 站位 -->
			<div style="padding-top: 2rem;"></div>
		<div class="hot-more-bottom">
			<div class="hot-more-bottom-item" style="background-color: #3487E2;" @click="activity()">查看活动详情</div>
			<div class="hot-more-bottom-item" style="background-color: #ff9900;" @click="order()">我的爆款订单</div>
		</div>
	</div>
</template>

<script>
	import {
		DropdownMenu,
		DropdownItem,
		List,
		Row,
		Col,
		Panel,
		Button,
		Search,
		Icon,
		Overlay,
		Picker,
		Popup,
		Field,
		Cell,
		CellGroup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Toast,
		Sticky,
		PullRefresh,
		Loading,
		Progress
	} from "vant";
    import {
        activity_detail,user_marketing_hot_goodslist,//爆款列表
    } from "@/request/api";
	export default {
		components: {
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			[List.name]: List,
			[Row.name]: Row,
			[Col.name]: Col,
			[Panel.name]: Panel,
			[Button.name]: Button,
			[Search.name]: Search,
			[Icon.name]: Icon,
			[Overlay.name]: Overlay,
			[Popup.name]: Popup,
			[Field.name]: Field,
			[Picker.name]: Picker,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Toast.name]: Toast,
			[Sticky.name]: Sticky,
			[PullRefresh.name]: PullRefresh,
			[Loading.name]: Loading,
			[Progress.name]: Progress
		},
		data() {
			return {
				page: 1,
				size: 10,
				goods_list: [],
				isLoading: false,
				finished: false,
				isloading: false,
				is_enroll: 0,
				activity_id: ''
			}
		},
        mounted: function() {
            this.activity_id = this.$route.query.id;
            this.loadData();
        },
		methods: {
            async loadData(){
                if (this.finished) {
                    return;
                }
                this.isloading = true;
                // 获取列表
                await user_marketing_hot_goodslist({rid: this.activity_id,page: this.page}).then(res => {
                    if(res.code == 1){
                        //  还有更多数据page++
                        if (res.data.has_more == true){
                            this.page++;
                            this.finished = false;
                        } else {
                            //没有数据了
                            this.finished = true
                        }
                        this.goods_list = this.goods_list.concat(res.data.data)
                    } else {
                        this.$dialog({message: res.msg});
                    }
                }).catch(err => {
					console.log(err)
                    this.$dialog({
                        message: "爆款数据获取失败，请重新刷新"
                    })
                });

                // 加载状态结束
                this.loading = false;
				this.isloading = false
			},
			onRefresh(){
				this.isLoading = false
				this.finished = false
				this.goods_list = [];
				this.page = 1;
				this.loadData();
			},
			toDetail(id){
				this.$router.push({path:'/activity/marketing/hot/detail',query:{id:id,activity_id:this.activity_id}})
			},
			activity(){
				console.log(this.activity_id)
                this.$router.push({path:'/activity',query:{id:this.activity_id}})
			},
			order(){
                this.$router.push({path:'/activity/marketing/hot/order',query:{activity_id:this.activity_id}})
			}
		},
		filters: {
		    //保留2位小数点过滤器 不四舍五入
		    number(value) {
		      var toFixedNum = Number(value).toFixed(3);
		      var realVal = toFixedNum.substring(0, toFixedNum.toString().length - 1);
		      return realVal;
		    }
		}
	}
</script>

<style lang="less">
	.group-list {
		.APP-field {
			width: 96%;
			padding: 2%;
			font-size: 12px;
			color: #757575;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}

		.APP-field>div {
			width: 47%;
			text-align: center;
			padding: 10px 0;
			border: 1px solid #e7e7e7;
		}

		.top-button-wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 10px 3px;
			justify-content: center;

			button {
				width: 100px;
			}
		}


		.Add-wrap {
		  position: fixed;
		  bottom: 20px;
		  right: 15px;
		  font-size: 45px;
		  color: #1989fa;
		}
	}
	
	.hot-more-bottom{
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-flow: row nowrap;
		background-color: white;
		height: 40px;
		width: 100%;
		overflow: hidden;
		text-align:center;
		border-top:1px solid #e9e9e9;
		z-index: 999;
		
		div{
			font-size: 15px;
			width: 50%;
			color: white;
			line-height: 40px;
			display: inline-block;
		}
	}
	
	
	
	.h-right{
		 text-align: center;
		 .h-price{
			font-size: 16px;
			 color: #3487E2;
		 }
		 .h-progress{
			 width: 2rem;
				margin: 0.2rem 0 0.2rem 0;
		 }
		 .h-sale{
			 font-size: 12px;
			 color: gray;
		 }
		 .h-btn{
			 font-size: 13px;
			 background-color:#3487E2;
			 color: white;
			 margin-top: 0.1rem;
			 padding-top: 0.1rem;
			 padding-bottom: 0.05rem;
			 border-radius: 0.1rem;
		 }
		 .van-progress__pivot{
		 	 display: none;
		 }
		 
	}
</style>
